import * as React from "react"
import Container from "react-bootstrap/Container";
import withLayout from "../hoc/withLayout";
import { CityPageHeader } from "../components/CityPageHeader";
import VitebskImg from "../images/gorod-vitebsk-lg.jpg";
import VitebskImgWebp from "../images/gorod-vitebsk-lg.jpg.webp";
import VitebskMapImg from "../images/gorod-vitebsk-map.png";
import VitebskMapImgWebp from "../images/gorod-vitebsk-map.png.webp";
import IndexPrice from "../components/index/price/Price";
import { CityPageDelivery } from "../components/CityPageDelivery";
import HelpPhone from "../components/HelpPhone";
import Ecology from "../components/Ecology";
import Map from "../components/Map";
import { CityPageAboutUs } from "../components/CityPageAboutUs";
import PhoneLink from "../components/PhoneLink";



const Vitebsk = () => {
  return (
    <>
      <CityPageHeader 
        header={<h1>Чистка сажевого фильтра в Витебске <small>и Витебской области</small></h1>} 
        jpg={VitebskImg} 
        webp={VitebskImgWebp} 
        alt="Чистка сажевого фильтра в Витебске и Витебской области"
      />

      <IndexPrice/>

      <Container className="mt-5">
        <div className="p-5 mb-4 rounded-3" style={{backgroundColor: 'rgba(5, 156, 210, 0.1)'}}>
          <h2>Наши партнёры в Витебске</h2>
          <p className="fs-4">Если вы из Витебска или Витебской области, вы можете почистить сажевый фильтр на современном оборудовании TOTAL DPF VS7 прямо в Витесбке у наших партнеров.</p>
          <p className="mt-2 mb-0 fs-4 fw-bold">АвтоКан Сервис</p>
          <p className="mb-0 fs-5 ">г. Витебск, ул.Воинов-Интернационалистов 1В</p>
          <p className="mb-0">
            <PhoneLink phone={{value: "+375 29 513-96-96", href: "tel:+375295139696"}} className="fs-5 fw-bold text-decoration-none"/>
            <br/>
            <PhoneLink phone={{value: "+375 29 613-96-96", href: "tel:+375296139696"}} className="fs-5 fw-bold text-decoration-none"/>
          </p>
        </div>
      </Container>
      
      <CityPageDelivery 
        from="Витебска и Витебской области" 
        jpg={VitebskMapImg}
        webp={VitebskMapImgWebp}
        alt="Доставка сажевых фильтров в Витебск и Витебскую область"
      />

      <HelpPhone/>
      <CityPageAboutUs/>
      <Ecology/>
      <Map/>
    </>
  )
}

export default withLayout(
  Vitebsk, 
  "Чистка сажевых фильтров в Витебске",
  "Удаление 99% загрязнений возникающих из сажи, пепла, масла из двигателя и охлаждающей жидкости, без повреждения картриджа фильтра");
